export async function copyToClipboard(str) {
  try {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      // Modern browsers with Clipboard API
      await navigator.clipboard.writeText(str);
    } else {
      // Fallback for older browsers
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
    console.log('Text copied to clipboard:', str);
  } catch (err) {
    console.error('Failed to copy text:', err);
  }
}